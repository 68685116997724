<template>
  <v-dialog persistent max-width="500" v-model="close">
    <v-card ref="form" class="pt-7">
      <v-card-text>
        <div class="headline font-weight-bold font--text">Confirm Password</div>
      </v-card-text>
      <v-card-text>
        Confirm Password for <b>{{ status == true ? 'Deactivation' : 'Activation' }}</b> Multi-factor authentication 
      </v-card-text>
      <v-card-text>
        <p class="font-weight-bold">Email</p>
        <v-text-field
          readonly
          v-model="email"
          outlined
          single-line
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <p class="font-weight-bold">Password</p>
        <v-text-field
          v-model="password"
          outlined
          single-line
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          @click:append="show2 = !show2"
          :error-messages="$v.password.$errors.length ? $v.password.$errors[0].$message : ''"
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              depressed
              height="45"
              block
              class="accent"
              @click="cancel"
            >Cancel</v-btn>
          </v-col>
          <v-col>
            <v-btn
              id="Add"
              depressed
              height="45"
              block
              class="secondary"
              @click="confirmPw"
            >Confirm</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import { useNamespacedActions } from 'vuex-composition-helpers';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { PROFILE } from './namespace';

export default {
  props: {
    close: {
      type: Boolean,
      default: false,
    },
    status: {
        type: Boolean,
        default: false,
    }
  },
  setup(props, context) {
    const { fetchUserProfile } = useNamespacedActions("ROLEPERMISSION", [
      "fetchUserProfile",
    ]);
    const { updateMfaStatus } = useNamespacedActions(PROFILE, ['updateMfaStatus']);
    const email = ref(localStorage.getItem("username"));
    const password = ref('');
    const show2 = ref(false);
    const mfaStatus = ref(props.status);
    const $v = useVuelidate({
      password: { required },
    }, {
      password
    });

    const confirmPw = async () => {
      $v.value.$touch();
      if ($v.value.$errors.length) return;

      let payload, resp 
      payload = {
        email: email.value,
        password: password.value,
        status: props.status ? false:true
      };
      resp = await updateMfaStatus(payload)
      if(resp.status){
        if (resp.status == false) {
          localStorage.removeItem("cid")
        }
        context.emit("update:close", false);
        fetchUserProfile()
      }else{
        context.emit("update:close", false);
      }
    };

    const cancel = () => {
      context.emit("update:close", false);
    };

    const mfaStatusLabel = computed(() => mfaStatus.value ? 'Activation' : 'Deactivation');
    return {
      email,
      password,
      mfaStatus,
      confirmPw,
      cancel,
      $v,
      show2,
      mfaStatusLabel
    };
  }
};
</script>

<style scoped>
/* Tambahkan gaya Anda di sini */
</style>
