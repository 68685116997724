var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v("Increase NFS Size")])]),_c('v-card-subtitle',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',[_c('span',{staticClass:"font-weight-bold mb-0"},[_vm._v("Select size for your NFS. Must be greater than "+_vm._s(_vm.nfs_detail.size)+" GB. This change is not reversible")]),(_vm.$v.selectedFlavor.$errors.length)?_c('span',{staticClass:"ml-2",class:{
              'error--text': _vm.$v.selectedFlavor.$errors.length,
            },staticStyle:{"font-size":"10px","position":"absolute"}},[_vm._v(" *"+_vm._s(_vm.$v.selectedFlavor.$errors[0].$message))]):_vm._e(),(_vm.$v.customQuota.$errors.length)?_c('span',{staticClass:"ml-2",class:{
              'error--text': _vm.$v.customQuota.$errors.length,
            },staticStyle:{"font-size":"10px","position":"absolute"}},[_vm._v(" *"+_vm._s(_vm.$v.customQuota.$errors[0].$message))]):_vm._e()]),(_vm.isFlavorLoading)?_c('v-row',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","offset":"4"}},[_vm._v("Loading Flavor . . ."),_c('beat-loader',{staticClass:"mr-2",attrs:{"color":"gray","size":"10px"}})],1)],1)],1):_c('v-slide-group',{staticClass:"py-4 pt-0",attrs:{"active-class":"success","show-arrows":"","loading":_vm.isFlavorLoading,"disabled":_vm.isFlavorLoading}},[_c('v-col',{staticClass:"d-flex pt-0 flex-wrap"},[_c('v-card',{class:("rounded-lg select-instance mb-4 " + (_vm.isCustomQuotaHighlighted ? 'hightlighted selected' : '')),staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"width":"170","border-color":"primary","outlined":""}},[_c('v-card-text',{staticClass:"pb-0 pt-4 d-flex flex-column m-0"},[_c('div',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateVolumeSize()))+"/mo")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateVolumeSize(undefined, "hour")))+"/hour")])])]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"px-4 pt-2 mt-2",staticStyle:{"width":"170px"},attrs:{"max":"100","flat":"","label":"Enter Size in GB"},on:{"keydown":function (ev) { return (_vm.customQuota = _vm.addGBText(ev, _vm.customQuota)); },"click":function () {
                        _vm.selectedFlavor = null;
                        _vm.isCustomQuotaHighlighted = true;
                      },"blur":function () { return (_vm.isCustomQuotaHighlighted = !_vm.customQuota ? false : true); }},model:{value:(_vm.customQuota),callback:function ($$v) {_vm.customQuota=$$v},expression:"customQuota"}})],1)],1),_vm._l((_vm.flavor_list),function(flavor){return _c('div',{key:flavor.id},[(flavor.volume_size)?_c('v-card',{staticClass:"rounded-lg select-instance mb-4",class:{
                    highlight: _vm.selectedFlavor ? _vm.is(_vm.selectedFlavor.id, flavor.id) : false,
                  },staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"width":"170","border-color":"primary","outlined":""},on:{"click":function () {
                      _vm.selectedFlavor = flavor;
                      _vm.customQuota = null;
                    }}},[_c('v-card-text',{staticClass:"pb-0 pt-5"},[_c('div',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateVolumeSize(flavor.price_per_month)))+"/mo")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.calculateVolumeSize(flavor.price_per_hour)))+"/hour")])])]),_c('v-divider'),_c('v-card-text',{staticClass:"flavor-card"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(flavor.volume_size)+" GB")])])],1):_vm._e()],1)})],2)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50","disabled":_vm.resizeLoading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"disabled":_vm.resizeLoading,"loading":_vm.resizeLoading,"color":"primary","block":"","height":"50","depressed":""},on:{"click":function () {_vm.resize()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }