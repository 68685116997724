var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-5 mt-3",attrs:{"flat":""}},[_c('v-container',[_c('div',{staticClass:"headline font-weight-bold font--text mb-4"},[_vm._v(" Custom Deka Flexi ")]),(_vm.isLoading)?_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{staticStyle:{"margin":"auto"},attrs:{"size":200,"color":"primary","indeterminate":""}})],1)],1):_c('div',[_c('div',{staticClass:"headline font-weight-bold darkblue--text mt-6"},[_vm._v(" Instance ")]),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Instances")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.instances.$errors.length
                    ? _vm.$v.instances.$errors[0].$message
                    : ''},model:{value:(_vm.instances),callback:function ($$v) {_vm.instances=$$v},expression:"instances"}}),_c('p',{staticClass:"font-weight-bold pt-6"},[_vm._v("Server Groups")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.server_groups.$errors.length
                    ? _vm.$v.server_groups.$errors[0].$message
                    : ''},model:{value:(_vm.server_groups),callback:function ($$v) {_vm.server_groups=$$v},expression:"server_groups"}}),_c('p',{staticClass:"font-weight-bold pt-6"},[_vm._v("Server Group Members")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.server_group_members.$errors.length
                    ? _vm.$v.server_group_members.$errors[0].$message
                    : ''},model:{value:(_vm.server_group_members),callback:function ($$v) {_vm.server_group_members=$$v},expression:"server_group_members"}})],1),_c('v-col',[_c('p',{staticClass:"font-weight-bold pt-6"},[_vm._v("RAM")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","suffix":"GB","type":"number","error-messages":_vm.$v.ram.$errors.length ? _vm.$v.ram.$errors[0].$message : ''},model:{value:(_vm.ram),callback:function ($$v) {_vm.ram=$$v},expression:"ram"}}),_c('p',{staticClass:"font-weight-bold pt-6"},[_vm._v("VCPUs")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.v_cpus.$errors.length
                    ? _vm.$v.v_cpus.$errors[0].$message
                    : ''},model:{value:(_vm.v_cpus),callback:function ($$v) {_vm.v_cpus=$$v},expression:"v_cpus"}})],1)],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"headline font-weight-bold darkblue--text mt-6"},[_vm._v("Volume")]),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Volumes")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.volumes.$errors.length
                    ? _vm.$v.volumes.$errors[0].$message
                    : ''},model:{value:(_vm.volumes),callback:function ($$v) {_vm.volumes=$$v},expression:"volumes"}}),_c('p',{staticClass:"font-weight-bold pt-6"},[_vm._v("Volume Storage")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","suffix":"GB","error-messages":_vm.$v.volume_storage.$errors.length
                    ? _vm.$v.volume_storage.$errors[0].$message
                    : ''},model:{value:(_vm.volume_storage),callback:function ($$v) {_vm.volume_storage=$$v},expression:"volume_storage"}})],1),_c('v-col',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Volume Snapshots")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.volume_snapshots.$errors.length
                    ? _vm.$v.volume_snapshots.$errors[0].$message
                    : ''},model:{value:(_vm.volume_snapshots),callback:function ($$v) {_vm.volume_snapshots=$$v},expression:"volume_snapshots"}})],1)],1)],1)],1),_c('v-divider'),_c('v-divider'),_c('div',{staticClass:"headline font-weight-bold darkblue--text mt-6"},[_vm._v("Network")]),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Floating IPs")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.floating_ips.$errors.length
                    ? _vm.$v.floating_ips.$errors[0].$message
                    : ''},model:{value:(_vm.floating_ips),callback:function ($$v) {_vm.floating_ips=$$v},expression:"floating_ips"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Security Groups Rules")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.security_group_rules.$errors.length
                    ? _vm.$v.security_group_rules.$errors[0].$message
                    : ''},model:{value:(_vm.security_group_rules),callback:function ($$v) {_vm.security_group_rules=$$v},expression:"security_group_rules"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Ports")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.ports.$errors.length ? _vm.$v.ports.$errors[0].$message : ''},model:{value:(_vm.ports),callback:function ($$v) {_vm.ports=$$v},expression:"ports"}})],1),_c('v-col',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Security Groups")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.security_groups.$errors.length
                    ? _vm.$v.security_groups.$errors[0].$message
                    : ''},model:{value:(_vm.security_groups),callback:function ($$v) {_vm.security_groups=$$v},expression:"security_groups"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Networks")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.networks.$errors.length
                    ? _vm.$v.networks.$errors[0].$message
                    : ''},model:{value:(_vm.networks),callback:function ($$v) {_vm.networks=$$v},expression:"networks"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Routers")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.routers.$errors.length
                    ? _vm.$v.routers.$errors[0].$message
                    : ''},model:{value:(_vm.routers),callback:function ($$v) {_vm.routers=$$v},expression:"routers"}})],1)],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"headline font-weight-bold darkblue--text mt-6"},[_vm._v(" Paid Os ")]),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold pt-6"},[_vm._v("Paid Os")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.paid_os.$errors.length ? _vm.$v.paid_os.$errors[0].$message : ''},model:{value:(_vm.paid_os),callback:function ($$v) {_vm.paid_os=$$v},expression:"paid_os"}})],1),_c('v-col')],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"headline font-weight-bold darkblue--text mt-6"},[_vm._v(" Backup Scheduler ")]),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold pt-6"},[_vm._v("Backup Scheduler")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"0","single-line":"","type":"number","error-messages":_vm.$v.backup_scheduler.$errors.length ? _vm.$v.backup_scheduler.$errors[0].$message : ''},model:{value:(_vm.backup_scheduler),callback:function ($$v) {_vm.backup_scheduler=$$v},expression:"backup_scheduler"}})],1),_c('v-col')],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"height":"58","depressed":"","block":"","color":"accent","to":("/organization-detail/" + _vm.organizationId),"exact":""}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" Cancel")])])],1),_c('v-col',[_c('v-btn',{attrs:{"height":"58","depressed":"","block":"","color":"secondary"},on:{"click":_vm.submit}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" Submit")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }