var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-7 rounded-0 rounded-b-lg",attrs:{"flat":""}},[(_vm.vpcList.length)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.vpcList,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: ("/network/vpc/" + (item.id)) }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.region",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.region)+" ")]}},{key:"item.subnets",fn:function(ref){
var item = ref.item;
return _vm._l((item.subnets),function(subnet){return _c('div',{key:subnet},[_vm._v(" "+_vm._s(subnet.ip_prefix)+"/"+_vm._s(subnet.network_size)+" ")])})}},{key:"item.resource",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.resources && item.resources.length ? ((item.resources.length) + " Resources") : "-")+" ")]}},{key:"item.billing_type",fn:function(ref){
var item = ref.item;
return [(item.billing_type == 'Trial' && item.trial_voucher_expired)?_c('span',{staticClass:"v-badge v-badge--inline theme--light"},[_vm._v(" "+_vm._s(item.billing_type)+" "),_c('span',{staticClass:"v-badge__wrapper"},[_c('span',{staticClass:"v-badge__badge pink",attrs:{"aria-atomic":"true","aria-label":"Badge","aria-live":"polite","role":"status"}},[_vm._v(" Expired ")])])]):(item.billing_type)?_c('span',[_vm._v(" "+_vm._s(item.billing_type)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                              on.click($event);                        
                              _vm.show($event, item);                        
                            }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{ rotate: item.open },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ path: ("/network/vpc/" + (item.id)) }}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{on:{"click":function () {
                            _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                              _vm.vpcselected = item;
                              _vm.opendialogdeletevpc = true;
                              _vm.DETAIL_VPC2({id: item.id})
                            });
                          }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                          var props = ref.props;
return [(_vm.vpcList.length > 5)?_c('custom-footer-datatable',{attrs:{"props":props}}):_vm._e()]}}],null,false,4245590273)}):_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any VPC Networks ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Resources assigned to the same VPC network can communicate securely with each other via private IP. Communication with resources outside the VPC must use a public network IP. ")])])],1),(!_vm.read_only)?_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"secondary mr-5",attrs:{"width":"100%","append":"","max-width":"150","height":"50","depressed":"","exact":""},on:{"click":function () {_vm.openSyncDialog = true;}}},[_vm._v(" Sync VPC Network ")]),_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":"","to":"/network/vpc/create"}},[_vm._v(" Create VPC Network ")])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('dialogDeleteVpc',{on:{"confirm":function () {
          _vm.vpcselected.type != 'Expert' ? _vm.deleteSelectedVPC() : _vm.deleteVpcAdvanced()
        }},model:{value:(_vm.opendialogdeletevpc),callback:function ($$v) {_vm.opendialogdeletevpc=$$v},expression:"opendialogdeletevpc"}}),(_vm.openSyncDialog)?_c('dialogSyncNetwork',{attrs:{"isLoading":_vm.isLoading},on:{"close":function($event){_vm.openSyncDialog = false}},model:{value:(_vm.openSyncDialog),callback:function ($$v) {_vm.openSyncDialog=$$v},expression:"openSyncDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }