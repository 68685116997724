var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-card',{attrs:{"color":"white","flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('p',{staticClass:"text-center"},[_c('beat-loader',{staticClass:"mr-2",attrs:{"color":"gray","size":"10px"}})],1)])],1):[(_vm.detail)?_c('v-card',{attrs:{"color":"white","flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('h6',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Summary Monthly")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('b',[_vm._v("Report ID")]),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.detail.id)+" ")])]),_c('v-col',[_c('b',[_vm._v("Billing Account")]),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.detail.billing_account)+" ")])]),_c('v-col',[_c('b',[_vm._v("Status")]),_c('p',{staticClass:"mt-4",class:{
                'green--text': _vm.detail.status === 'PAID',
              }},[_vm._v(" "+_vm._s(_vm.detail.status)+" ")])]),_c('v-col',[_c('b',[_vm._v("Project Name")]),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.detail.project.name)+" ")])]),_c('v-col',[_c('b',[_vm._v("VAT ID")]),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.detail.project.vat_id)+" ")])])],1)],1)],1):_vm._e(),(_vm.detail)?_c('v-card',{staticClass:"mt-8",attrs:{"color":"white","flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('v-data-table',{staticClass:"mt-8",attrs:{"hide-default-footer":true,"headers":_vm.tblHeaders,"items-per-page":-1,"items":_vm.list},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY hh:mm:ss"))+" ")]}},{key:"item.DeletedAt",fn:function(ref){
              var item = ref.item;
return [(item.DeleedAt)?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.DeletedAt).format("DD/MM/YYYY hh:mm:ss"))+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.qty",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.qty)+" h ")]}},{key:"item.service_type",fn:function(ref){
              var item = ref.item;
return [(_vm.titleCase(item.service_type) == 'OS CHARGING')?_c('div',[_vm._v(" OPERATING SYSTEM ")]):_c('div',[_vm._v(_vm._s(_vm.titleCase(item.service_type)))])]}},{key:"item.price",fn:function(ref){
              var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.price))+" / "+_vm._s(item.price_unit)+" ")])]}},{key:"item.discount_value",fn:function(ref){
              var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.discount_value))+" ")])]}},{key:"item.billed",fn:function(ref){
              var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.billed))+" ")])]}}],null,false,1236951604)}),_c('v-row',{staticClass:"mr-2"},[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"grey lighten-4"},[_c('v-col',[_c('b',[_vm._v("Subtotal")])]),_c('v-col',{style:({textAlign:'right'})},[_c('b',[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.detail.price))+" ")])])],1),_c('v-row',{staticClass:"grey lighten-4 mt-1"},[_c('v-col',[_c('b',[_vm._v(" Total Discount ")])]),_c('v-col',{style:({textAlign:'right'})},[_c('b',[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.detail.total_discount))+" ")])])],1),_c('v-row',{staticClass:"grey lighten-4 mt-1"},[_c('v-col',[_c('b',[_vm._v(" Credit ")])]),_c('v-col',{style:({textAlign:'right'})},[_c('b',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.detail.credit))+" ")])])],1),(_vm.isPostpaid)?_c('v-row',{staticClass:"grey lighten-4 mt-1"},[_c('v-col',[_c('b',[_vm._v(" PPN ("+_vm._s(_vm.detail.ppn)+"%) ")])]),_c('v-col',{style:({textAlign:'right'})},[_c('b',[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.detail.ppn_value ? _vm.detail.ppn_value : 0))+" ")])])],1):_vm._e(),_c('v-row',{staticClass:"mt-1 primary"},[_c('v-col',[_c('b',{staticClass:"white--text"},[_vm._v("Total")])]),_c('v-col',{style:({textAlign:'right'})},[_c('b',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.detail.billed - _vm.detail.credit))+" ")])])],1)],1)],1)],1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }