var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Edit Organization ")])]),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Details")]),_c('v-textarea',{attrs:{"maxlength":"255","placeholder":"Enter Details","flat":"","outlined":"","error-messages":_vm.$v.details.$errors.length ? _vm.$v.details.$errors[0].$message : ''},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Address")]),_c('v-textarea',{attrs:{"maxlength":"255","placeholder":"Enter Address","flat":"","outlined":"","error-messages":_vm.$v.address.$errors.length ? _vm.$v.address.$errors[0].$message : ''},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Phone Number")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:([
          /[1-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/ ]),expression:"[\n          /[1-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n        ]"}],attrs:{"outlined":"","prefix":"+62","placeholder":"Enter Phone Number","error-messages":_vm.$v.phone.$errors.length ? _vm.$v.phone.$errors[0].$message : ''},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("NPWP Number")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"Enter NPWP Number","disabled":"","error-messages":_vm.$v.npwp.$errors.length ? _vm.$v.npwp.$errors[0].$message : ''},model:{value:(_vm.npwp),callback:function ($$v) {_vm.npwp=$$v},expression:"npwp"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Sales")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.saleses,"error-messages":"","placeholder":_vm.isNotSuperadmin ? '-' : 'Select Sales',"item-text":"fullname","item-value":"id","disabled":_vm.isNotSuperadmin,"filled":_vm.isNotSuperadmin},model:{value:(_vm.sales_id),callback:function ($$v) {_vm.sales_id=$$v},expression:"sales_id"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"depressed":"","height":"45","block":""},on:{"click":function () {
                _vm.$emit('input', false);
              }}},[_vm._v("Cancel")])],1),_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","height":"45","block":""},on:{"click":function () {
                _vm.submit();
              }}},[_vm._v("Update")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }