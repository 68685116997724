<template>
  <div>
    <v-row>
      <v-col cols="12" class="pt-3">
        <v-card flat class="rounded-lg">
          <v-container fluid class="pa-7" id="container">
            <v-row>
              <v-col cols="12">
                <div class="headline font-weight-bold font--text">
                  Create NFS
                </div>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col md="6" sm="12" class="mt-0">
                <p class="font-weight-bold">Storage Type</p>
                <v-select
                  v-model="type_id"
                  outlined
                  placeholder="Select a Storage Type"
                  :items="listStorageTypeBilling"
                  item-value="id"
                  :error-messages="$v.type_id.$errors.length ? $v.type_id.$errors[0].$message : ''"
                >
                  <template v-slot:selection="{ item }"> {{ item.name }} - {{ item.description }} </template>
                  <template v-slot:item="{ item }"> {{ item.name }} - {{ item.description }} </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row v-if="type_id">
              <v-col class="pb-0" cols="12">
                <p :class="{ 'error--text': $v.selectedFlavor.$errors.length }">
                  <span class="font-weight-bold mb-0">Select Volume Size</span>
                  <span class="ml-2" style="font-size: 10px; position: absolute" v-if="$v.selectedFlavor.$errors.length">
                    *{{ $v.selectedFlavor.$errors[0].$message }}</span
                  >
                </p>
              </v-col>
              <v-row v-if="isFlavorLoading"> 
                <v-row>
                  <v-col cols="4" offset="4">Loading Flavor  . . .<br<beat-loader color="gray" size="10px" class="mr-2" /></v-col>
                </v-row>
              </v-row>
              <v-slide-group v-else class="py-4 pt-0" active-class="success" show-arrows :loading="isFlavorLoading" :disabled="isFlavorLoading">
                <v-col class="d-flex pt-0 flex-wrap">
                  <v-card
                    width="170"
                    border-color="primary"
                    outlined
                    :class="`rounded-lg select-instance mb-4 ${isCustomQuotaHighlighted ? 'hightlighted selected' : ''}`"
                    style="text-align: center; margin-right: 20px"
                  >
                    <v-card-text class="pb-0 pt-4 d-flex flex-column m-0">
                      <div>
                        <p class="font-weight-bold mb-0">{{ toIDRWithDotFormat(calculateVolumeSize()) }}/mo</p>
                        <p class="font-weight-bold">{{ toIDRWithDotFormat(calculateVolumeSize(undefined, "hour")) }}/hour</p>
                      </div>
                    </v-card-text>
                    <v-divider />

                    <v-card-text class="pa-0">
                      <v-text-field
                        v-model="customQuota"
                        class="px-4 pt-2 mt-2"
                        @keydown="(ev) => (customQuota = addGBText(ev, customQuota))"
                        style="width: 170px"
                        max="100"
                        flat
                        label="Enter Size in GB"
                        @click="
                          () => {
                            selectedFlavor = null;
                            isCustomQuotaHighlighted = true;
                          }
                        "
                        @blur="() => (isCustomQuotaHighlighted = !customQuota ? false : true)"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                  <div v-for="flavor in flavor_list" :key="flavor.id">
                    <v-card
                      v-if="flavor.volume_size"
                      width="170"
                      :class="{
                        highlight: selectedFlavor ? is(selectedFlavor.id, flavor.id) : false,
                      }"
                      @click="
                        () => {
                          selectedFlavor = flavor;
                          customQuota = null;
                        }
                      "
                      border-color="primary"
                      outlined
                      class="rounded-lg select-instance mb-4"
                      style="text-align: center; margin-right: 20px"
                    >
                      <!-- <v-card-text class="pb-0 pt-5"> -->
                      <v-card-text v-if="type_id" class="pb-0 pt-5">
                        <div>
                          <p class="font-weight-bold mb-0">{{ toIDRWithDotFormat(calculateVolumeSize(flavor.price_per_month)) }}/mo</p>
                          <p class="font-weight-bold">{{ toIDRWithDotFormat(calculateVolumeSize(flavor.price_per_hour)) }}/hour</p>
                        </div>
                      </v-card-text>
                      <v-divider />
                      <v-card-text class="flavor-card">
                        <p class="font-weight-bold mb-0">{{ flavor.volume_size }} GB</p>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>
              </v-slide-group>
            </v-row>

            <v-divider class="mb-3" />

            <v-row>
              <v-col md="6" sm="12" class="mt-0">
                <p><b>Billing Type</b></p>
                <v-select
                  id="billingType"
                  placeholder="Choose Billing Type"
                  v-model="selectedBillingType"
                  :items="billingTypes"
                  :error-messages="$v.selectedBillingType.$errors.length ? $v.selectedBillingType.$errors[0].$message : ''"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" sm="12" class="mt-0">
                <p class="font-weight-bold">Zone</p>
                <v-select :items="zoneByProject" id="SelectRegion" outlined placeholder="Choose Zone"
                item-text="name"
                item-value="name"
                  v-model="selectedRegion"
                  :error-messages="$v.selectedRegion.$errors.length ? $v.selectedRegion.$errors[0].$message : ''"></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" sm="12" class="mt-0">
                <p class="font-weight-bold">Choose a unique name</p>
                <v-text-field maxlength="50" :error-messages="createErrorMessage('name')" id="ChooseUniqueName"
                  v-model="name" outlined placeholder="Type a unique for your NFS name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" sm="12" class="mt-0">
                <p class="font-weight-bold">Set Mounting Path</p>
                <v-text-field maxlength="50" :error-messages="createErrorMessage('path')" id="ChooseUniquePath"
                  v-model="path" outlined></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col md="6" sm="12" class="mt-0">
                <p class="font-weight-bold">Network Subnet</p>
                <v-text-field maxlength="50" :error-messages="createErrorMessage('networkSubnet')" id="ChooseUniqueNetworkSubnet"
                  v-model="networkSubnet" outlined placeholder="Using Valid IPv4"></v-text-field>
              </v-col>
            </v-row> -->
            <!-- <v-divider class="my-4" /> -->
            <v-row>
              <v-col md="6" sm="12" class="mt-0">
                <p class="font-weight-bold">Choose VPC</p>
                <v-select :items="vpcList" id="SelectVPC" outlined placeholder="Choose VPC"
                item-text="name"
                item-value="id"
                  v-model="selectedVPC"
                  :error-messages="$v.selectedVPC.$errors.length ? $v.selectedVPC.$errors[0].$message : ''"></v-select>
              </v-col>
              <!-- <v-col sm="6" md="6">
                <p class="font-weight-bold">Zone</p>
                <v-select :items="zoneByProject" id="SelectRegion" outlined placeholder="Choose Region"
                item-text="name"
                item-value="name"
                  v-model="selectedRegion"
                  :error-messages="$v.selectedRegion.$errors.length ? $v.selectedRegion.$errors[0].$message : ''"></v-select>
              </v-col> -->
            </v-row>

            <v-row>
              <v-col md="6" sm="12" class="mt-0">
                <v-row style="height: 50px;">
                  <div class="col-4 bottom-right-border">
                    <p class="font-weight-bold">Service Instance</p>
                  </div>
                  <div class="col-1 bottom-right-border">
                    <v-checkbox class="font-weight-bold" v-model="si"></v-checkbox>
                  </div>
                </v-row>
                <v-text-field
                  placeholder="input service instance"
                  single-line
                  outlined
                  v-model="service_instance"
                  type="number"
                  :error-messages="errorInputSI"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0">
                <div class="d-flex align-center">
                  <v-checkbox v-model="agree">
                    <template v-slot:label>
                      <div>By Creating Instance You Agree To The</div>
                    </template>
                  </v-checkbox>

                  <button type="button" class="text-decoration-underline primary--text ml-2 fz-16" style="margin-top: -27px;" @click="getEula()">Terms of Service</button>
                </div>
              </v-col>
            </v-row>

            <!-- <div style="width: auto; height: 10px" />
            <v-divider /> -->
            <div style="width: auto; height: 10px" />
            <v-row>
              <v-col cols="6">
                <v-btn depressed block height="50" class="accent" to="/network-file-system" :disabled="isLoading">Cancel</v-btn></v-col>
              <v-col cols="6">
                <v-btn depressed id="CreateNFS" @click="create" :disabled="isLoading || !agree" block height="50" class="secondary">
                  <beat-loader v-if="isLoading" :loading="isLoading" :color="'white'" :size="'10px'"></beat-loader>
                  <span v-else> Create </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
  const { required, helpers, minLength, ipAddress, requiredIf, minValue } = require("@vuelidate/validators");
  import { ref, onMounted, watch } from "@vue/composition-api";
  import { NFS } from "@/modules/network-file-system/namespace";
  import { MANAGEZONE } from "@/modules/superadmin-manageflavors/namespace";
  import { NETWORKVPC } from "@/modules/network/vpc/namespace";
  import { STORAGE } from "@/modules/storage/namespace";
  import { addGBText, removeGBText } from '@/lib/formatter';
  import { isValidIPv4 } from "@/lib/inputValidator";
  const useVuelidate = require("@vuelidate/core").default;
  import { toIDRWithDotFormat } from "@/lib/formatter";
  
  import { useNamespacedActions, useNamespacedState, useNamespacedGetters } from "vuex-composition-helpers";
  export default {
    setup(props, context) {
      const currentProj = ref(JSON.parse(localStorage.getItem("currentProj")));
      const defaultBillingTypes = [
        {
          text: "Pay Per Use (PPU)",
          value: "PPU",
        },
        // {
        //   text: "Trial",
        //   value: "Trial",
        // },
      ];

      const fixedPostpaid = [
        {
          text: "Fixed",
          value: "Fixed",
        },
        // {
        //   text: "Trial",
        //   value: "Trial",
        // },
      ];
      const projJson = localStorage.getItem("currentProj");
      const projObj = projJson && projJson !== "undefined" ? JSON.parse(projJson) : {};
      const postpaid_type = projObj.postpaid_type;

      const billingTypes = ref(postpaid_type && postpaid_type === "fixed" ? fixedPostpaid : defaultBillingTypes);
      
      const type_id = ref("");
      const selectedBillingType = ref(null);
      const selectedFlavor = ref(null);
      const selectedRegion = ref(null);
      const selectedVPC = ref(null);
      // const networkSubnet = ref(null);
      const name = ref("");
      const path = ref("/mnt/nfs");
      const isLoading = ref(false);
      const isFlavorLoading = ref(false);
      const isCustomQuotaHighlighted = ref(false);
      const size = ref(null);
      const selectedS3Flavor = ref(null);
      const customQuota = ref(null);
      const agree = ref(false)

      //adding SI
      const service_instance = ref('')
      const si = ref(false)
      const errorInputSI = ref("")
  
      const { createNFS, fetchFlavor } = useNamespacedActions(NFS, ['createNFS', 'fetchFlavor'])
      const { flavor_list } = useNamespacedState(NFS, ['flavor_list'])

      const { fetchZoneByProject } = useNamespacedActions(MANAGEZONE, ["fetchZoneByProject"]);
      const { zoneByProject } = useNamespacedState(MANAGEZONE, ["zoneByProject"]);

      const {FETCH_VPC} = useNamespacedActions(NETWORKVPC, ["FETCH_VPC"]);
      const {vpcList} = useNamespacedState(NETWORKVPC, ["vpcList"]);

      const { getListStorageType, getDefaultPriceStorage, } = useNamespacedActions(STORAGE, [
        "getListStorageType", "getDefaultPriceStorage",
      ]);

      const { listStorageTypeBilling, defaultPriceStorage } = useNamespacedGetters(STORAGE, [
        "listStorageTypeBilling",
        "defaultPriceStorage",
      ]);
  
      const $v = useVuelidate({
        name: {
          required,
          minLength: minLength(5),
          $autoDirty: true,
          clean: helpers.withMessage('Can only contains lowercase letters, numbers, and dash.', (val) => {
            return !val.match(/([^a-z0-9-])+/g)
          })
        },
        path:{ required},
        type_id: { required},
        selectedBillingType: { required},
        selectedRegion: { required },
        selectedVPC:  {required},
        customQuota: {
          required: requiredIf((val) => {
            return selectedFlavor.value === null;
          }),
        },
        selectedFlavor: { 
          required: requiredIf((val) => {
            return customQuota.value === null;
          }),
          },
      }, { 
        name,
        selectedFlavor, 
        selectedRegion, 
        selectedBillingType,
        selectedVPC, 
        path,
        type_id,
        customQuota
      });
    
      function createErrorMessage(element) {
        return $v.value[element].$errors.length
          ? $v.value[element].$errors[0].$message
          : "";
      }

      const calculateCustomVolumeSize = (discount = 0, unit = "month") => {
        const size = customQuota.value ? customQuota.value : 0;

        let price = 0;
        if (unit == "hour") {
          price = flavor_list.value[0].hour_price;
        } else {
          price = flavor_list.value[0].month_price;
        }

        let total = 0;
        total = price * size;

        if (discount) {
          discount = (price * size * discount) / 100;
          total = total - discount;
        }

        // return toIDRWithDotFormat(Math.round(total));
        return Math.round(total);
      };

      const calculateVolumeSize = (price, discount = 0) => {
        let total = 0;
        total = price;

        if (discount) {
          discount = (price * discount) / 100;
          total = total - discount;
        }

        // return toIDRWithDotFormat(Math.round(total));
        return Math.round(total);
      };

      watch(type_id, async(newVal, oldVal) => {
        isFlavorLoading.value = true
        await fetchFlavor({params:{storage_type_id:newVal, min_volume_size:30}})
        isFlavorLoading.value = false
      })
  
      async function create() {
        $v.value.$touch();
        if ($v.value.$errors.length) {
          let container = document.getElementById("container");
          container.scrollIntoView({behavior: "smooth"})
          return;
        }

        if (si.value == true && service_instance.value == ""){
          return (errorInputSI.value = "Service Instance couldnt be empty.");
        }

        let payload = {
          type_id: type_id.value,
          name: name.value,
          project_id: currentProj.value.id,
          vpc: selectedVPC.value,
          path: path.value,
          zone: selectedRegion.value,
          network_number: service_instance.value
        }
        selectedFlavor.value ? payload.custom_size = selectedFlavor.value.volume_size: payload.package_storage_id = parseInt(customQuota.value)
        isLoading.value = true
        const response = await createNFS(payload);
        isLoading.value = false
        if (response && response.status === 201) {
          this.$router.replace("/network-file-system");
        }
      }
  
      onMounted(async () => {
        const params = { project_id: currentProj.value.id };
        await fetchZoneByProject(params);
        await FETCH_VPC()
        
        const paramsType = new URLSearchParams();
        paramsType.append("project_id", currentProj.value.id);
        await getListStorageType(paramsType);
      });
  
      return {
        type_id,
        size,
        agree,
        name,
        path,
        // networkSubnet,
        selectedBillingType,
        billingTypes,
        selectedFlavor,
        selectedRegion,
        selectedVPC,
        flavor_list,
        zoneByProject,
        vpcList,
        isLoading,
        isFlavorLoading,
        isCustomQuotaHighlighted,
        $v,
        selectedS3Flavor,
        addGBText,
        create,
        createErrorMessage,
        calculateCustomVolumeSize,
        calculateVolumeSize,
        customQuota,
        toIDRWithDotFormat,
        listStorageTypeBilling,
        //adding SI
        service_instance,
        si,
        errorInputSI,
      };
    },
    methods: {
      async getEula() {
        const routeData = this.$router.resolve({
          name: "eulaContent",
          query: { form_name: "Form Create Deka NFS" },
          path: "/eula-content",
        });
        window.open(routeData.href, "_blank");
      },
      is(a, b) {
        return a == b;
      },
    }
  };
  </script>

<style lang="scss" scoped>
::v-deep .v-text-field__details {
  position: static;
}
.select-storage {
  cursor: pointer;
  &.selected {
    border-color: #2c94d2;
    .v-divider {
      border-color: #2c94d2;
    }
  }
}
.bordererror {
  border: 1px solid red;
}
.v-input--selection-controls {
    margin-top: -6px !important;
}
</style>

