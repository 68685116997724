var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-4",attrs:{"flat":""}},[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" My Account Settings ")])])],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-4",attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"mr-4"},[_c('v-icon',{staticStyle:{"font-size":"60px"},attrs:{"color":"#F1F2F2"}},[_vm._v("mdi-circle")])],1),_c('div',{staticClass:"d-flex flex-column align-self-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" "+_vm._s(_vm.userprofile.email)+" ")]),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"12px","color":"#a5b3bf"}},[_vm._v(" Member since "+_vm._s(_vm.$moment(new Date(_vm.userprofile.createdate)).format( "DD/MM/YYYY" ))+" ")]),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v("Sales:")]),_vm._v(" "+_vm._s(_vm.currentOrg ? (_vm.currentOrg.sales && _vm.currentOrg.sales.fullname ? _vm.currentOrg.sales.fullname : '-') : '-')+" ")]),(_vm.service_type !== 'undefined')?_c('p',{style:({marginTop: '8px', display: 'flex', alignItems: 'center', color: _vm.service_type === 'postpaid' ? '#27AE60' : '#A5B3BF'})},[_c('img',{style:({marginRight: '4px'}),attrs:{"src":_vm.service_type === 'postpaid' ? '/postpaid.png' : '/prepaid.png',"alt":"postpaid-logo"}}),_vm._v(" "+_vm._s(_vm.uppercaseFirstLetter(_vm.service_type))+" ")]):_vm._e()]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-self-center"},[_c('v-btn',{staticClass:"secondary--text",attrs:{"depressed":"","outlined":"","height":"50","width":"150"},on:{"click":function () {
                    _vm.opendialogchangepassword = true;
                  }}},[_vm._v("Change Password")]),_c('div',{staticStyle:{"height":"auto","width":"28px"}}),_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","height":"50","width":"150"},on:{"click":function () {
                    _vm.memberselected = _vm.userprofile;
                    _vm.opendialogeditprofile = true;
                  }}},[_vm._v("Edit Profile")])],1)],1)]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-4",staticStyle:{"font-size":"60px","visibility":"hidden"},attrs:{"color":"#F1F2F2"}},[_vm._v("mdi-circle")]),_c('p',[_c('b',[_vm._v("Full Name:")]),_c('br'),_vm._v(" "+_vm._s(_vm.userprofile.fullname)+" ")])],1),_c('v-col',{staticClass:"justify-left d-flex"},[_c('p',[_c('b',[_vm._v("Phone Number:")]),_c('br'),_vm._v(" "+_vm._s(_vm.userprofile.phone)+" ")])]),_c('v-col',{staticClass:"justify-left d-flex"},[_c('p',[_c('b',[_vm._v("Address:")]),_c('br'),_vm._v(" "+_vm._s(_vm.userprofile.address)+" ")])]),_c('v-col',{staticClass:"justify-left d-flex"},[_c('p',[_c('b',[_vm._v("Multi-factor authentication : ")]),_c('v-col',{staticClass:"label-container"},[_c('v-switch',{staticStyle:{"margin-top":"2px"},attrs:{"input-value":_vm.userprofile.mfaActive,"readonly":""},on:{"click":_vm.onStatusChange}}),_c('span',{staticClass:"label-style",style:({ color: _vm.userprofile.mfaActive ? 'green' : 'red'})},[_vm._v(" "+_vm._s(_vm.userprofile.mfaActive ? 'Active' : 'Inactive')+" ")])],1)],1)])],1)],1)],1)],1),_c('v-col',{style:({ 'display': _vm.isShowSshGrid ? '': 'none' }),attrs:{"md":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-4",attrs:{"flat":""}},[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" SSH Keys ")]),_c('v-btn',{staticClass:"secondary mb-5 ml-auto",attrs:{"id":"CreateNewAttach","depressed":"","height":"50","width":"150"},on:{"click":function () {
                _vm.userId = _vm.userprofile.id;
                _vm.opendialogaddssh = true;
              }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Add SSH Key")])])],1),_c('div',[_c('v-container',{staticClass:"px-4"},[[_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.sshkeys,"items-per-page":10,"server-items-length":_vm.pagination.count,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fingerprint",fn:function(ref){
              var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.fingerprint !== "" ? item.fingerprint : "-")+" "),(item.fingerprint)?_c('span',{staticClass:"primary--text pr-3 delete ml-4",on:{"click":function($event){return _vm.copyToClipBoard(item.fingerprint)}}},[_vm._v("Copy")]):_vm._e()])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"error--text pr-3 delete ml-4",on:{"click":function () {
                        _vm.ssh = item.id;
                        _vm.userId = _vm.userprofile.id;
                        _vm.openDialogDeleteSsh = true;
                      }}},[_vm._v("Delete")]),_c('span',{staticClass:"primary--text pr-3 delete ml-4",on:{"click":function () {
                        _vm.sshItem = item;
                        _vm.openDialogUpdate = true;
                      }}},[_vm._v("Edit")])]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.options = Object.assign({}, _vm.options, val);
                      }}})]}}],null,true)})]],2)],1)],1)],1)],1),(_vm.memberselected && _vm.opendialogeditprofile)?_c('dialogEditProfile',{attrs:{"userselected":_vm.memberselected},model:{value:(_vm.opendialogeditprofile),callback:function ($$v) {_vm.opendialogeditprofile=$$v},expression:"opendialogeditprofile"}}):_vm._e(),(_vm.opendialogchangepassword)?_c('dialodchangepassword',{model:{value:(_vm.opendialogchangepassword),callback:function ($$v) {_vm.opendialogchangepassword=$$v},expression:"opendialogchangepassword"}}):_vm._e(),(_vm.sshItem && _vm.openDialogUpdate)?_c('dialogupdatessh',{attrs:{"ssh":_vm.sshItem},on:{"close":function($event){_vm.openDialogUpdate = false}},model:{value:(_vm.openDialogUpdate),callback:function ($$v) {_vm.openDialogUpdate=$$v},expression:"openDialogUpdate"}}):_vm._e(),(_vm.userId && _vm.opendialogaddssh)?_c('dialogcreatessh',{attrs:{"userid":_vm.userId},on:{"close":function($event){_vm.opendialogaddssh = false}},model:{value:(_vm.opendialogaddssh),callback:function ($$v) {_vm.opendialogaddssh=$$v},expression:"opendialogaddssh"}}):_vm._e(),(_vm.ssh && _vm.userId && _vm.openDialogDeleteSsh)?_c('dialogdeletessh',{attrs:{"ssh-id":_vm.ssh,"user-id":_vm.userId},on:{"close":function($event){_vm.openDialogDeleteSsh = false}},model:{value:(_vm.openDialogDeleteSsh),callback:function ($$v) {_vm.openDialogDeleteSsh=$$v},expression:"openDialogDeleteSsh"}}):_vm._e(),(_vm.openDialogPw)?_c('dialog-pw',{attrs:{"status":_vm.userprofile.mfaActive,"close":_vm.openDialogPw},on:{"update:close":function($event){_vm.openDialogPw=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }