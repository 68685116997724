<template>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          <div class="headline font-weight-bold font--text pb-4">Increase NFS Size</div>
        </v-card-title>
        <v-card-subtitle flat class="rounded-lg">

        <!-- <v-text-field
          maxlength="50"
          :error-messages="$v.size.$errors.length ? $v.size.$errors[0].$message : ''" 
          id="Size"
          v-model="size"
          @keydown="(ev) => (size = addGBText(ev, size))" outlined placeholder="Input Size">
        </v-text-field> -->

          <v-row>
            <v-col class="pb-0" cols="12">
              <p>
                <span class="font-weight-bold mb-0">Select size for your NFS. Must be greater than {{ nfs_detail.size }} GB. This change is not reversible</span>
                <span
                :class="{
                  'error--text': $v.selectedFlavor.$errors.length,
                }" class="ml-2" style="font-size: 10px; position: absolute" v-if="$v.selectedFlavor.$errors.length">
                *{{ $v.selectedFlavor.$errors[0].$message }}</span>
                <span
                :class="{
                  'error--text': $v.customQuota.$errors.length,
                }" class="ml-2" style="font-size: 10px; position: absolute" v-if="$v.customQuota.$errors.length">
                *{{ $v.customQuota.$errors[0].$message }}</span>
              </p>
              <v-row v-if="isFlavorLoading"> 
                <v-row>
                  <v-col cols="4" offset="4">Loading Flavor  . . .<br<beat-loader color="gray" size="10px" class="mr-2" /></v-col>
                </v-row>
              </v-row>
              <v-slide-group v-else class="py-4 pt-0" active-class="success" show-arrows :loading="isFlavorLoading" :disabled="isFlavorLoading">
                <v-col class="d-flex pt-0 flex-wrap">
                  <v-card
                    width="170"
                    border-color="primary"
                    outlined
                    :class="`rounded-lg select-instance mb-4 ${isCustomQuotaHighlighted ? 'hightlighted selected' : ''}`"
                    style="text-align: center; margin-right: 20px"
                  >
                    <v-card-text class="pb-0 pt-4 d-flex flex-column m-0">
                      <div>
                        <p class="font-weight-bold mb-0">{{ toIDRWithDotFormat(calculateVolumeSize()) }}/mo</p>
                        <p class="font-weight-bold">{{ toIDRWithDotFormat(calculateVolumeSize(undefined, "hour")) }}/hour</p>
                      </div>
                    </v-card-text>
                    <v-divider />

                    <v-card-text class="pa-0">
                      <v-text-field
                        v-model="customQuota"
                        class="px-4 pt-2 mt-2"
                        @keydown="(ev) => (customQuota = addGBText(ev, customQuota))"
                        style="width: 170px"
                        max="100"
                        flat
                        label="Enter Size in GB"
                        @click="
                          () => {
                            selectedFlavor = null;
                            isCustomQuotaHighlighted = true;
                          }
                        "
                        @blur="() => (isCustomQuotaHighlighted = !customQuota ? false : true)"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                  <div v-for="flavor in flavor_list" :key="flavor.id">
                    <v-card
                      v-if="flavor.volume_size"
                      width="170"
                      :class="{
                        highlight: selectedFlavor ? is(selectedFlavor.id, flavor.id) : false,
                      }"
                      @click="
                        () => {
                          selectedFlavor = flavor;
                          customQuota = null;
                        }
                      "
                      border-color="primary"
                      outlined
                      class="rounded-lg select-instance mb-4"
                      style="text-align: center; margin-right: 20px"
                    >
                      <!-- <v-card-text class="pb-0 pt-5"> -->
                      <v-card-text class="pb-0 pt-5">
                        <div>
                          <p class="font-weight-bold mb-0">{{ toIDRWithDotFormat(calculateVolumeSize(flavor.price_per_month)) }}/mo</p>
                          <p class="font-weight-bold">{{ toIDRWithDotFormat(calculateVolumeSize(flavor.price_per_hour)) }}/hour</p>
                        </div>
                      </v-card-text>
                      <v-divider />
                      <v-card-text class="flavor-card">
                        <p class="font-weight-bold mb-0">{{ flavor.volume_size }} GB</p>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>
              </v-slide-group>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn color="accent" block depressed height="50" :disabled="resizeLoading" @click="dialog = false">Cancel</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
              :disabled="resizeLoading"
              :loading="resizeLoading"
                color="primary"
                block
                height="50"
                @click="() => {resize()}"
                depressed>
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>


<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import { addGBText, removeGBText } from '@/lib/formatter';
import { useNamespacedActions, useNamespacedState } from "vuex-composition-helpers";
import { NFS } from "@/modules/network-file-system/namespace";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, minValue } from "@vuelidate/validators";
import { toIDRWithDotFormat } from "@/lib/formatter";

export default {
  props: ["value", "nfs_detail", "nfs_id"],
  setup(props, context) {
    const resizeLoading = ref(false)
    const isFlavorLoading = ref(false)
    const size = ref(0);
    const selectedFlavor = ref(null);
    const customQuota = ref(null);
    const isCustomQuotaHighlighted = ref(false);

    const { updateNFS, getNFSList, fetchFlavor } = useNamespacedActions(NFS, ["updateNFS", "getNFSList", "fetchFlavor"]);
    const { flavor_list } = useNamespacedState(NFS, ['flavor_list'])


    const $v = useVuelidate(
      {
        // size: { required },
        customQuota: {
          required: requiredIf((val) => {
            return selectedFlavor.value === null;
          }),
        },
        selectedFlavor: { 
          required: requiredIf((val) => {
            return customQuota.value === null;
          }),
         },
      },
      { 
        // size
        selectedFlavor, customQuota 
      }
    );

    onMounted(async() => {
      isFlavorLoading.value = true
      await fetchFlavor({params:{storage_type_id:props.nfs_detail.type_id, min_volume_size:props.nfs_detail.size+1}})
      isFlavorLoading.value = false
    })

    const calculateCustomVolumeSize = (discount = 0, unit = "month") => {
      const size = customQuota.value ? customQuota.value : 0;

      let price = 0;
      if (unit == "hour") {
        price = flavor_list.value[0].hour_price;
      } else {
        price = flavor_list.value[0].month_price;
      }

      let total = 0;
      total = price * size;

      if (discount) {
        discount = (price * size * discount) / 100;
        total = total - discount;
      }

      // return toIDRWithDotFormat(Math.round(total));
      return Math.round(total);
    };

    const calculateVolumeSize = (price, discount = 0) => {
      let total = 0;
      total = price;

      if (discount) {
        discount = (price * discount) / 100;
        total = total - discount;
      }

      // return toIDRWithDotFormat(Math.round(total));
      return Math.round(total);
    };

    const dialog = computed({
      get: () => props.value,
      set: (val) => {
        context.emit("input", val);
      },
    });

    async function resize() {
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      let payload = {}
      selectedFlavor.value ?  payload.package_storage_id =  selectedFlavor.value.id : payload.custom_size = parseInt(customQuota.value)
      resizeLoading.value = true
      let res = await updateNFS({
        id: props.nfs_detail.id,
        payload: payload
      });
      resizeLoading.value = false
      if (res && res.status >= 200 && res.status <= 300) {
        await getNFSList({params:{project_id:props.nfs_detail.project_id}});
      }
      dialog.value = false;
    }
    return {
        resizeLoading,
        isFlavorLoading,
        size,
        dialog,
        $v,
        flavor_list,
        addGBText,
        resize,
        calculateCustomVolumeSize,
        calculateVolumeSize,
        toIDRWithDotFormat,
        selectedFlavor,
        customQuota,
        isCustomQuotaHighlighted
    }
  },
  methods: {
    is(a, b) {
      return a == b;
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__details {
  position: static;
}
.select-storage {
  cursor: pointer;
  &.selected {
    border-color: #2c94d2;
    .v-divider {
      border-color: #2c94d2;
    }
  }
}
.bordererror {
  border: 1px solid red;
}
</style>